<template>
  <div :id="'uploader-'+index">
    <a-form-model-item>

    <a-radio-group @change="" button-style="solid"
                   v-model="item.link_type">
      <a-radio-button value="image">
        Upload Image
      </a-radio-button>
      <a-radio-button value="image_source">
        Add Image Source
      </a-radio-button>
    </a-radio-group>
<span style="font-size: 20px;
    line-height: 32px;" v-if="index==0"> (Main Image) </span>
    </a-form-model-item>


    <a-form-model-item style="margin-bottom: 0" v-if="item.link_type=='image'"
                       :prop="'resources.' + index + '.image'"
                       :rules="{
        required: true,
        message: 'Image is required',
        trigger: 'blur',
      }">
      <a-upload
          :default-file-list="item.image"
          @preview="handlePreview"
          :before-upload="beforeUpload"
          :multiple="false"
          list-type="picture-card"
          :remove="handleRemove"
          accept=".jpeg,.jpg,.gif,.png"

      >
        <div v-if="item.image < 1">
          <p class="ant-upload-drag-icon" style="margin-bottom: 15px;">
            <a-icon type="inbox" style="color: #40a9ff;font-size: 40px;"/>
          </p>
          <p class="ant-upload-text" style="margin: 0 0 4px;
    color: rgba(0, 0, 0, 0.85);
    font-size: 16px;">
            Click or drag file to this area to upload
          </p>
          <p class="ant-upload-hint" style="color: rgba(0, 0, 0, 0.45);
    font-size: 14px;">
            Only single file with maximum file size of 5 MB is supported.
          </p>
<!--          <a-icon type="plus" />-->
<!--          <div class="ant-upload-text">-->
<!--            Click or Drag & Drop Image Here To Upload-->
<!--          </div>-->
        </div>
      </a-upload>
      <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
        <img alt="example" style="width: 100%" :src="previewImage" />
      </a-modal>

    </a-form-model-item>
    <a-form-model-item v-if="item.link_type=='image_source'" label="External Image Url"
                       :prop="'resources.' + index + '.image_source'"
                       :rules="{
        required: true,
        message: 'Image source is required',
        trigger: 'change',
      }">
      <a-input
          placeholder="Input an image source"
          v-model="item.image_source"

      />
      <div v-if="item.link_type&&item.link_type=='image_source'&&item.image_source">
        <img :src="item.image_source" alt="Invalid Image URL" class="source-preview"/>
      </div>
    </a-form-model-item>
    <a-form-model-item label="Image Source Platform Name"
    >
      <a-input
          placeholder="Input an image source platform name like Eventbrite, Facebook"
          v-model="item.source_name"
      />

    </a-form-model-item>

  </div>
</template>
<script>
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}
export default {
  data() {
    return {
      previewVisible: false,
      previewImage: '',
      url: process.env.VUE_APP_SERVER_URL,
    };
  },
  props:['item','index'],

  methods: {
    handleCancel() {
      this.previewVisible = false;
    },
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },

    beforeUpload(file) {
      if (this.item.image.length < 1)
      this.item.image = [...this.item.image, file];
      return false;
    },
    handleRemove() {
      this.item.image = [];
    },
    forceUpdateDom() {
      setTimeout(()=>{
        this.$forceUpdate()
      },10)
    },
    resetValue(){
      // debugger
      // this.formRef.getFieldDecorator(`image[${this.k}]`,{value:''})
      // this.formRef.getFieldDecorator(`image_source[${this.k}]`,{value:''})
      // this.item.image=[]
    }

  },
  mounted() {
    this.$forceUpdate()
    // if (this.eventResource){
    //   if (this.eventResource.type=='image'){
    //   this.item.image= [
    //     {
    //       uid: this.eventResource._id,
    //       name: this.eventResource.uploadPath,
    //       status: 'done',
    //       url: this.url+this.eventResource.uploadPath,
    //       thumbnail: this.url+this.eventResource.uploadPath,
    //     },
    //   ]
    //
    // }
    //
    // }
  }

};
</script>
<style>
/* you can make up upload button and sample style by using stylesheets */
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
.ant-upload.ant-upload-select-picture-card{
  width: 100% !important;
}

</style>








<!--&lt;!&ndash;<script src="../../../../../eventsmgmt-nodeserver/app/models/token.js"></script>&ndash;&gt;-->
<!--<template>-->
<!--  <div class="clearfix">-->
<!--    <a-form-item>-->

<!--      <a-radio-group @change="(!eventResource?handleRemove():''),forceUpdateDom()" button-style="solid"-->
<!--                     v-decorator="[-->
<!--              `link_type[${k}]`,{-->
<!--      initialValue:eventResource?eventResource.type:'image'-->
<!--              }]" >-->
<!--        <a-radio-button value="image">-->
<!--          Upload Image-->
<!--        </a-radio-button>-->
<!--        <a-radio-button value="image_source">-->
<!--          Add Image Source-->
<!--        </a-radio-button>-->
<!--      </a-radio-group>-->
<!--      <span style="font-size: 20px;-->
<!--    line-height: 32px;" v-if="index==0"> (Main Image) </span>-->
<!--    </a-form-item>-->


<!--    <a-form-item style="margin-bottom: 0" v-if="formRef.getFieldValue(`link_type[${k}]`)=='image'">-->
<!--      <a-upload-->
<!--          list-type="picture-card"-->
<!--          :default-file-list="fileList"-->
<!--          @preview="handlePreview"-->
<!--          :before-upload="handleChange"-->
<!--          :multiple="false"-->
<!--          :remove="handleRemove"-->
<!--          v-decorator="[-->
<!--              `image[${k}]`,-->
<!--                        { rules: [{ required: true, message: 'Image is required' }] ,-->
<!--                                        initialValue:fileList,-->
<!--                                        }-->
<!--            ]"-->
<!--          accept=".jpeg,.jpg,.gif,.png"-->
<!--      >-->
<!--        <div v-if="fileList&&fileList.length < 1">-->
<!--          <p class="ant-upload-drag-icon" style="margin-bottom: 15px;">-->
<!--            <a-icon type="inbox" style="color: #40a9ff;font-size: 40px;"/>-->
<!--          </p>-->
<!--          <p class="ant-upload-text" style="margin: 0 0 4px;-->
<!--    color: rgba(0, 0, 0, 0.85);-->
<!--    font-size: 16px;">-->
<!--            Click or drag file to this area to upload-->
<!--          </p>-->
<!--          <p class="ant-upload-hint" style="color: rgba(0, 0, 0, 0.45);-->
<!--    font-size: 14px;">-->
<!--            Only single file with maximum file size of 5 MB is supported.-->
<!--          </p>-->
<!--          &lt;!&ndash;          <a-icon type="plus" />&ndash;&gt;-->
<!--          &lt;!&ndash;          <div class="ant-upload-text">&ndash;&gt;-->
<!--          &lt;!&ndash;            Click or Drag & Drop Image Here To Upload&ndash;&gt;-->
<!--          &lt;!&ndash;          </div>&ndash;&gt;-->
<!--        </div>-->
<!--      </a-upload>-->
<!--      <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">-->
<!--        <img alt="example" style="width: 100%" :src="previewImage" />-->
<!--      </a-modal>-->

<!--    </a-form-item>-->
<!--    <a-form-item v-if="formRef.getFieldValue(`link_type[${k}]`)=='image_source'" label="External Image Url">-->
<!--      <a-input-->
<!--          placeholder="Input an image source"-->
<!--          v-decorator="[-->

<!--              `image_source[${k}]`,-->
<!--                        { rules: [{ required: true, message: 'Image Source is required' }],-->
<!--                        initialValue:this.eventResource&&eventResource.type=='image_source'?eventResource.link:''-->
<!--              }-->
<!--            ]"-->
<!--      />-->
<!--      <div :id="`preview${k}`" v-if="formRef.getFieldValue(`link_type[${k}]`)&&formRef.getFieldValue(`link_type[${k}]`)=='image_source'&&formRef.getFieldValue(`image_source[${k}]`)">-->
<!--        <img :src="formRef.getFieldValue(`image_source[${k}]`)" alt="Invalid Image URL" style="width: 140px"/>-->
<!--      </div>-->
<!--      {{formRef.getFieldValue(`link_type[${k}]`)}}-->
<!--      {{formRef.getFieldValue(`image_source[${k}]`)}}-->
<!--    </a-form-item>-->


<!--    <a-form-item v-show="false">-->
<!--      <a-input-->
<!--          v-decorator="[-->

<!--              `id[${k}]`,-->
<!--                        {-->
<!--                        initialValue:this.eventResource?eventResource._id:''-->
<!--              }-->
<!--            ]"-->
<!--      />-->

<!--    </a-form-item>-->
<!--    <a-form-item v-show="false">-->
<!--      <a-input-->
<!--          v-decorator="[-->

<!--              `position[${k}]`,-->
<!--                        {-->
<!--                        initialValue:this.eventResource?eventResource.position:''-->
<!--              }-->
<!--            ]"-->
<!--      />-->

<!--    </a-form-item>-->
<!--  </div>-->
<!--</template>-->
<!--<script>-->
<!--function getBase64(file) {-->
<!--  return new Promise((resolve, reject) => {-->
<!--    const reader = new FileReader();-->
<!--    reader.readAsDataURL(file);-->
<!--    reader.onload = () => resolve(reader.result);-->
<!--    reader.onerror = error => reject(error);-->
<!--  });-->
<!--}-->
<!--export default {-->
<!--  data() {-->
<!--    return {-->
<!--      previewVisible: false,-->
<!--      previewImage: '',-->
<!--      url: process.env.VUE_APP_SERVER_URL,-->
<!--      item.image: [-->

<!--      ],-->
<!--    };-->
<!--  },-->
<!--  props:['k','formRef','index','eventResource'],-->
<!--  methods: {-->
<!--    handleCancel() {-->
<!--      this.previewVisible = false;-->
<!--    },-->
<!--    async handlePreview(file) {-->
<!--      if (!file.url && !file.preview) {-->
<!--        file.preview = await getBase64(file.originFileObj);-->
<!--      }-->
<!--      this.previewImage = file.url || file.preview;-->
<!--      this.previewVisible = true;-->
<!--    },-->
<!--    handleChange({ fileList }) {-->
<!--      if (this.fileList.length < 1)-->
<!--        this.fileList = fileList;-->

<!--      return false;-->
<!--    },-->
<!--    handleRemove() {-->
<!--      this.fileList = [];-->
<!--    },-->
<!--    forceUpdateDom() {-->
<!--      setTimeout(()=>{-->
<!--        this.$forceUpdate()-->
<!--      },10)-->
<!--    },-->
<!--    resetValue(){-->
<!--      // debugger-->
<!--      // this.formRef.getFieldDecorator(`image[${this.k}]`,{value:''})-->
<!--      // this.formRef.getFieldDecorator(`image_source[${this.k}]`,{value:''})-->
<!--      // this.fileList=[]-->
<!--    }-->

<!--  },-->
<!--  mounted() {-->
<!--    this.$forceUpdate()-->
<!--    if (this.eventResource){-->
<!--      if (this.eventResource.type=='image'){-->
<!--        this.fileList= [-->
<!--          {-->
<!--            uid: this.eventResource._id,-->
<!--            name: this.eventResource.uploadPath,-->
<!--            status: 'done',-->
<!--            url: this.url+this.eventResource.uploadPath,-->
<!--            thumbnail: this.url+this.eventResource.uploadPath,-->
<!--          },-->
<!--        ]-->

<!--      }-->

<!--    }-->
<!--  }-->

<!--};-->
<!--</script>-->
<!--<style>-->
<!--/* you can make up upload button and sample style by using stylesheets */-->
<!--.ant-upload-select-picture-card i {-->
<!--  font-size: 32px;-->
<!--  color: #999;-->
<!--}-->

<!--.ant-upload-select-picture-card .ant-upload-text {-->
<!--  margin-top: 8px;-->
<!--  color: #666;-->
<!--}-->
<!--.ant-upload.ant-upload-select-picture-card{-->
<!--  width: 100% !important;-->
<!--}-->

<!--</style>-->

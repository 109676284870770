var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":'uploader-'+_vm.index}},[_c('a-form-model-item',[_c('a-radio-group',{attrs:{"button-style":"solid"},on:{"change":function($event){}},model:{value:(_vm.item.link_type),callback:function ($$v) {_vm.$set(_vm.item, "link_type", $$v)},expression:"item.link_type"}},[_c('a-radio-button',{attrs:{"value":"image"}},[_vm._v(" Upload Image ")]),_c('a-radio-button',{attrs:{"value":"image_source"}},[_vm._v(" Add Image Source ")])],1),(_vm.index==0)?_c('span',{staticStyle:{"font-size":"20px","line-height":"32px"}},[_vm._v(" (Main Image) ")]):_vm._e()],1),(_vm.item.link_type=='image')?_c('a-form-model-item',{staticStyle:{"margin-bottom":"0"},attrs:{"prop":'resources.' + _vm.index + '.image',"rules":{
        required: true,
        message: 'Image is required',
        trigger: 'blur',
      }}},[_c('a-upload',{attrs:{"default-file-list":_vm.item.image,"before-upload":_vm.beforeUpload,"multiple":false,"list-type":"picture-card","remove":_vm.handleRemove,"accept":".jpeg,.jpg,.gif,.png"},on:{"preview":_vm.handlePreview}},[(_vm.item.image < 1)?_c('div',[_c('p',{staticClass:"ant-upload-drag-icon",staticStyle:{"margin-bottom":"15px"}},[_c('a-icon',{staticStyle:{"color":"#40a9ff","font-size":"40px"},attrs:{"type":"inbox"}})],1),_c('p',{staticClass:"ant-upload-text",staticStyle:{"margin":"0 0 4px","color":"rgba(0, 0, 0, 0.85)","font-size":"16px"}},[_vm._v(" Click or drag file to this area to upload ")]),_c('p',{staticClass:"ant-upload-hint",staticStyle:{"color":"rgba(0, 0, 0, 0.45)","font-size":"14px"}},[_vm._v(" Only single file with maximum file size of 5 MB is supported. ")])]):_vm._e()]),_c('a-modal',{attrs:{"visible":_vm.previewVisible,"footer":null},on:{"cancel":_vm.handleCancel}},[_c('img',{staticStyle:{"width":"100%"},attrs:{"alt":"example","src":_vm.previewImage}})])],1):_vm._e(),(_vm.item.link_type=='image_source')?_c('a-form-model-item',{attrs:{"label":"External Image Url","prop":'resources.' + _vm.index + '.image_source',"rules":{
        required: true,
        message: 'Image source is required',
        trigger: 'change',
      }}},[_c('a-input',{attrs:{"placeholder":"Input an image source"},model:{value:(_vm.item.image_source),callback:function ($$v) {_vm.$set(_vm.item, "image_source", $$v)},expression:"item.image_source"}}),(_vm.item.link_type&&_vm.item.link_type=='image_source'&&_vm.item.image_source)?_c('div',[_c('img',{staticClass:"source-preview",attrs:{"src":_vm.item.image_source,"alt":"Invalid Image URL"}})]):_vm._e()],1):_vm._e(),_c('a-form-model-item',{attrs:{"label":"Image Source Platform Name"}},[_c('a-input',{attrs:{"placeholder":"Input an image source platform name like Eventbrite, Facebook"},model:{value:(_vm.item.source_name),callback:function ($$v) {_vm.$set(_vm.item, "source_name", $$v)},expression:"item.source_name"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }